import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Banner } from '../components/banner';
import { TiggbeeContactUs } from '../components/tiggbee-contact-us';
import { BusinesspageBannerContent } from '../components/businesspage-banner-content';
import { BusinesspageContent } from '../components/businesspage-content';
// import business_page_banner from '../images/business-page-banner.jpg';

const BusinessPage = () => (
    <Layout>
        <SEO title="Business Page" />
        <Banner
            imgURL='business-page-banner.jpg'
            imgHeight={536}
            content={<BusinesspageBannerContent />}
        />
        <BusinesspageContent />
        <TiggbeeContactUs
          titleElement={<>Our Mission is to empower our students regardless of economic or geographic barriers with the hope and knowledge of the opportunities to be happy and successful individuals.</>}
        />
    </Layout>
)

export default BusinessPage
