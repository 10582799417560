import React from "react"
import { Link } from "gatsby"
import "./content.css"

export class BusinesspageContent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  setLeaderPart = () => {
    const content = (
      <>
        <div className="homepage-content-technology-container businesspage-content-leader-container">
          <div className="homepage-content-technology-title">
            Industry Leaders
          </div>
          <div className="homepage-content-technology-description businesspage-content-technology-description">
            By sharing your brand, products and services with students, you have
            the power to ignite curiosity and fuel aspirations for a fulfilling
            career
            <img
              alt=""
              className="businesspage-content-technology-paragraph-icon"
              src={"/business-page-leader-paragraph-icon.png"}
            />
          </div>
          <div className="homepage-content-technology-paragraph-container businesspage-content-technology-paragraph-container">
            <img
              alt=""
              className="businesspage-content-technology-paragraph-icon"
              src={"/business-page-leader-paragraph-icon.png"}
            />
            <div className="businesspage-content-technology-paragraph-background">
              <img alt="" src={"/business-page-leader-background.png"} />
            </div>
            <div className="businesspage-content-technology-paragraph-content">
              <div className="businesspage-content-technology-paragraph-text">
                Tiggbee expands your digital marketing and in-person outreach
                programs, anchoring your company in a broader community. Your
                video content serves as a visual manifestation of your values
                and aspirations. By partnering with schools and educators, we
                provide students with a firsthand understanding of how their
                skills and talents align with the opportunities available at
                your company.
              </div>
              <div className="businesspage-content-technology-paragraph-options">
                <div className="businesspage-content-technology-paragraph-option">
                  <img
                    alt=""
                    src={"/business-page-leader-paragraph-options-icon.png"}
                  />
                  <div>Gain consumers</div>
                </div>
                <div className="businesspage-content-technology-paragraph-option">
                  <img
                    alt=""
                    src={"/business-page-leader-paragraph-options-icon.png"}
                  />
                  <div>Create passionate employees</div>
                </div>
                <div className="businesspage-content-technology-paragraph-option">
                  <img
                    alt=""
                    src={"/business-page-leader-paragraph-options-icon.png"}
                  />
                  <div>Offer new possibilities to students everywhere</div>
                </div>
              </div>
              <Link
                className="homepage-content-technology-paragraph-button businesspage-content-technology-paragraph-button"
                to="/bee-inspirational"
              >
                Contact Us
              </Link>
              {/* <a
                href="https://event.tiggbee.com/authentication/createaccount"
                className="homepage-content-technology-paragraph-button businesspage-content-technology-paragraph-button"
              >
                Contact Us
              </a> */}
            </div>
          </div>
        </div>
      </>
    )
    return content
  }

  setImpactPart = () => {
    const content = (
      <>
        <div className="homepage-content-technology-container businesspage-content-technology-container">
          <div className="businesspage-content-technology-impact">
            We minimize your time while maximizing your impact on our future
            workforce.
          </div>
          <div
            className="homepage-content-technology-paragraph-container businesspage-content-impact-paragraph-container"
            style={{
              backgroundImage: `url(/schoolpage-content-sharing-knowledge-background.png)`,
            }}
          >
            <div className="homepage-content-technology-paragraph-texts businesspage-content-technology-paragraph-texts">
              <div>
                Our students are excited to engage with your business to get an
                inside look within your industry
              </div>
              <div>
                Your marketing department can appreciate the lasting impact that
                professionals sharing their excitement in their work, in the
                products they develop and the company they work for can have.
              </div>
              <div>
                Tiggbee works with industry partners like you to create and
                schedule pre-recorded and live events, interactive projects,
                live collaboration along with follow-up Question & Answer
                sessions.
              </div>
            </div>
          </div>
        </div>
      </>
    )
    return content
  }

  setHowItWorkPart = () => {
    const content = (
      <>
        <div className="homepage-content-technology-container businesspage-content-how-it-work-container">
          <div className="businesspage-content-how-it-work-background">
            <img alt="" src={"/business-page-how-it-work-background.png"} />
          </div>
          <div className="businesspage-content-how-it-work-guide">
            <div className="businesspage-content-how-it-work-title">
              How it works
            </div>
            <div className="businesspage-content-technology-paragraph-options businesspage-content-how-it-work-paragraph-options">
              <div className="businesspage-content-technology-paragraph-option">
                <img
                  alt=""
                  src={"/business-page-leader-paragraph-options-icon.png"}
                />
                <div>Join Tiggbee @ www.tiggbee.com</div>
              </div>
              <div className="businesspage-content-technology-paragraph-option">
                <img
                  alt=""
                  src={"/business-page-leader-paragraph-options-icon.png"}
                />
                <div>Shape your content and message with our creative team</div>
              </div>
              <div className="businesspage-content-technology-paragraph-option">
                <img
                  alt=""
                  src={"/business-page-leader-paragraph-options-icon.png"}
                />
                <div>Set your event date</div>
              </div>
              <div className="businesspage-content-technology-paragraph-option">
                <img
                  alt=""
                  src={"/business-page-leader-paragraph-options-icon.png"}
                />
                <div>
                  Students participate in your event with questions and/or
                  projects
                </div>
              </div>
              <div className="businesspage-content-technology-paragraph-option">
                <img
                  alt=""
                  src={"/business-page-leader-paragraph-options-icon.png"}
                />
                <div>Respond to the student's most popular questions</div>
              </div>
              <div className="businesspage-content-technology-paragraph-option">
                <img
                  alt=""
                  src={"/business-page-leader-paragraph-options-icon.png"}
                />
                <div>Repeat and grow your audience and your brand presence</div>
              </div>
            </div>
            <a
              href="https://event.tiggbee.com/authentication/createaccount"
              className="homepage-content-technology-paragraph-button businesspage-content-technology-paragraph-button"
            >
              Create account
            </a>
          </div>
        </div>
      </>
    )
    return content
  }

  render() {
    const html = (
      <>
        {this.setLeaderPart()}
        {this.setImpactPart()}
        {this.setHowItWorkPart()}
      </>
    )
    return html
  }
}
